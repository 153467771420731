import clamp from '@/clamp-fn';
import { Ref, ref } from 'vue';

interface SearchSuggestionsController {
	debug: boolean,
	state: Ref<{
		suggestions: string[],
		selectedIndex: number
	}>,
	selectNext(): boolean,
	selectPrevious(): boolean,
	updateSuggestions(suggestions: string[]): void,
	selectedSuggestion(): string | null
}

const searchSuggestionsController: SearchSuggestionsController = {

	debug: true,

	state: ref({
		suggestions: [],
		selectedIndex: -1
	}),

	selectNext(): boolean {
		if (this.state.value.selectedIndex + 1 > this.state.value.suggestions.length - 1) return false;

		this.state.value.selectedIndex = clamp(this.state.value.selectedIndex + 1, -1, this.state.value.suggestions.length);
		if (this.debug) console.debug(`selected ${this.state.value.selectedIndex}: ${this.selectedSuggestion() }`);
		return true;
	},
	selectPrevious(): boolean {
		if (this.state.value.selectedIndex < 0) return false;

		this.state.value.selectedIndex = clamp(this.state.value.selectedIndex - 1, -1, this.state.value.suggestions.length);
		if (this.debug) console.debug(`selected ${this.state.value.selectedIndex}: ${this.selectedSuggestion() }`);
		return true;
	},

	updateSuggestions(suggestions: string[]): void {
		this.state.value.suggestions = suggestions;
		this.state.value.selectedIndex = -1;
	},

	selectedSuggestion(): string | null {
		if (this.state.value.selectedIndex < 0) return null;

		return this.state.value.suggestions[this.state.value.selectedIndex];
	}
};

export { searchSuggestionsController as default };
