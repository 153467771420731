<template>
	<span v-if="!expanded" :title="exchanged">{{ original }}</span>
	<span v-else
		>{{ original }}
		<span v-if="showExchanged">({{ exchanged }})</span>
	</span>
</template>

<script setup lang="ts">
/**
 * A component that displays a currency amount in the currently selected currency.
 */
import { exchange } from '@/currencies';
import { selectedCurrency } from '@/currency-selector';
import { localizedCurrencyAmount } from '@/i18n';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
	/**
	 * The lower bound of the range expressed in the default currency.
	 */
	amount: {
		type: Number,
		required: true,
	},
	/**
	 * The default currency.
	 */
	currency: {
		type: String,
		required: true,
	},

	/**
	 * If true, the amount will be shown both in the original and selected currency.
	 */
	expanded: {
		type: String,
		default: null,
	},

	precision: {
		type: Number,
		default: 0,
	},
});

const exchanged = ref('');

onMounted(() => {
	updateExchangedAmount();
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const original = computed(() => {
	return localizedCurrencyAmount(props.amount, props.currency, props.precision);
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const showExchanged = computed(() => {
	if (exchanged.value === '') {
		return false;
	}

	return props.currency !== selectedCurrency.value;
});

const updateExchangedAmount = async () => {
	try {
		const exchangedAmount = await exchange(
			props.amount,
			props.currency,
			selectedCurrency.value
		);

		exchanged.value = localizedCurrencyAmount(
			Math.round(exchangedAmount),
			selectedCurrency.value,
			props.precision
		);
	} catch (e) {
		console.error(e);
		exchanged.value = original.value;
	}
};
</script>
