import lotEvents from '@/lot-events';
import {refresh} from '@/current-page';
import { displayNewBid } from '@/announced-bid';
import restAPI from '@/br-api';
import {debounce} from "ts-debounce";

// If a given lot is present as a list item on the current page, invokes
// the callback with that list item.
const ifLotPresentInList = function(lotId: string, callback: (el: HTMLElement) => void) {
	const li = (document.getElementById(`lot_${ lotId }`));
	if (li != null) { callback(li); }
};

const refreshDebouncedForOnline = debounce(refresh, 1000);
const refreshDebouncedForTraditional = debounce(refresh, 3000);

const refreshListTraditional = async function(timestamp: number): Promise<void> {
	if (document.querySelector('ul.lots.traditional') != null) {
		await refreshDebouncedForTraditional(timestamp);
	}
};

const refreshListNotTraditional = async function(timestamp: number): Promise<void> {
	if (document.querySelector('ul.lots:not(.traditional)') != null) {
		await refreshDebouncedForOnline(timestamp);
	}
};

function onBid(lotId: string, timestamp: number) {
	return ifLotPresentInList(lotId, li => restAPI.getAnnouncedBids(lotId, timestamp, announcedBids => {
		const topBid = announcedBids[0];
		if (topBid == null) {
			return;
		}
		const container = li.querySelector<HTMLElement>('.status-container');
		if (container == null) throw new Error(`no status container for lot ${ lotId }`);
		displayNewBid(container, topBid.bidAmount.amount)
	}));
}

function refreshListIfLotPresent(lotId: string, timestamp: number): void {
	ifLotPresentInList(lotId, () => {
		refreshListTraditional(timestamp).catch((reason: unknown) => { throw new Error(`failed to update list: ${ reason }`)});
		refreshListNotTraditional(timestamp).catch((reason: unknown) => { throw new Error(`failed to update list: ${ reason }`)});
	});
}

lotEvents.on('*', refreshListIfLotPresent);

lotEvents.on('onlineBidRegistered', onBid);
lotEvents.on('bidRegistered', onBid);
