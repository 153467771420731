import {animationFrame} from '@/function';
import {width, SizeType, offset} from '@/br-dom';
import { ready } from '@/ready';

const constrainInViewport = function(el: HTMLElement) {
	el.style.transform = '';

	const offsetLeft = offset(el).left;
	const offsetRight = width(window) - (offsetLeft + width(el, SizeType.OUTER));

	// Early return if default transformation is acceptable
	if ((offsetLeft > 0) && (offsetRight > 0)) { return; }

	// Need to base new transformation on the position of the untransformed element
	el.style.transform = 'none';
	const delta = offsetLeft - offset(el).left;

	if (offsetLeft < 0) {
		el.style.transform = `translateX(${ -offsetLeft + delta }px)`;
	} else {
		el.style.transform = `translateX(${ offsetRight + delta }px)`;
	}
};

const applyConstraint = () => {
	document.querySelectorAll<HTMLElement>('[data-clamp-position]').forEach(constrainInViewport);
};

ready(applyConstraint);
window.addEventListener('resize', animationFrame(applyConstraint));

