import cookies from 'js-cookie';
import { translate } from '@/i18n';
import {ref} from "vue";
import {onDelegatedEvent} from "@/domEvents";

const selectedAttr = 'aria-selected';
const currencyCookie = 'currency';

export const selectedCurrency = ref(cookies.get(currencyCookie) || 'DKK');

const setCurrency = function(currency: string) {
	selectedCurrency.value = currency;
	return cookies.set(currencyCookie, currency, {expires: 365});
};

onDelegatedEvent('click', '#currency-selector a', function(ev, el) {

	const currency = el.innerText;
	if ((currency !== 'DKK') && !confirm(translate('currencies.alert', {currency}))) {
		el.blur();
		return false;
	}
	const currentSelection = document.querySelector('#currency-selector .current-selection') as HTMLElement;
	const selectedOption = document.querySelector(`#currency-selector [aria-selected]`) as HTMLElement;
	currentSelection.innerText = currency;
	selectedOption.setAttribute(selectedAttr, selectedAttr);
	el.removeAttribute(selectedAttr);
	el.blur();

	return setCurrency(currency);
});

