import TimeDistance from '@/timeDistance';
import { translate as t, pluralize } from '@/i18n';

const r = Math.round;
const f = Math.floor;

const nonEmpty = (n: string) => Boolean(n);

const compound = (...units: Array<string>): string => units.filter(nonEmpty).join(` ${ t('countdown.and') } `);

type Options = {
	showSeconds: boolean,
	nowString: string,
	roundToQuarterHour: boolean,

}


const defaultOptions = { showSeconds: false, nowString: '', roundToQuarterHour: false};


export function inWords (duration: TimeDistance, options: Options = defaultOptions): string {

	let hourString, minuteString: string;
	const {
		showSeconds,
		nowString
	} = options;

	const translationKey = duration.isNegative() ? 'countdown.timeAgo' : 'countdown.timeLeft';

	duration = duration.abs();

	if (duration.isShorterThan(TimeDistance.seconds(1))) {
		return nowString;

	} else if (showSeconds && duration.isShorterThan(TimeDistance.minutes(2))) {

		const minutesLeft = pluralize(f(duration.inMinutes()), 'countdown.minutes');
		const secondsLeft = pluralize((f(duration.inSeconds()) % TimeDistance.minutes(1).inSeconds()), 'countdown.seconds');

		return t(translationKey, {duration: compound(minutesLeft, secondsLeft)});

	} else if (showSeconds && duration.isShorterThan(TimeDistance.minutes(3))) {
		return t(translationKey, {duration: t('countdown.lessThanThreeMinutes')});

	} else if (!showSeconds && duration.isShorterThan(TimeDistance.minutes(1))) {
		return t(translationKey, {duration: t('countdown.lessThanOneMinute')});

	} else if (duration.isShorterThan(TimeDistance.minutes(15)) || (duration.isShorterThan(TimeDistance.days(1)) && (options.roundToQuarterHour == null))) {

		hourString = pluralize(f(duration.inHours()), 'countdown.hours');
		minuteString = pluralize((r(duration.inMinutes()) % TimeDistance.hours(1).inMinutes()), 'countdown.minutes');

		return t(translationKey, {duration: compound(hourString, minuteString)});

	} else if (duration.isShorterThan(TimeDistance.days(1))) {

		hourString = pluralize(f(duration.inHours()), 'countdown.hours');
		const minutes = r(duration.inMinutes() % TimeDistance.hours(1).inMinutes());
		const minutesRounded = 5*Math.floor(minutes / 5);
		minuteString = pluralize(minutesRounded, 'countdown.minutes');

		return t(translationKey, {duration: compound(hourString, minuteString)});

	} else if (duration.isShorterThan(TimeDistance.days(2))) {

		const daysString = pluralize(f(duration.inDays()), 'countdown.days');
		hourString = pluralize((r(duration.inHours()) % TimeDistance.days(1).inHours()), 'countdown.hours');

		return t(translationKey, {duration: compound(daysString, hourString)});

	} else {
		const timeLeft = pluralize(f(duration.inDays()), 'countdown.days');
		return t(translationKey, {duration: timeLeft});
	}
}
