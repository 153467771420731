import * as cookie from 'js-cookie'
import {ready} from "@/ready";

const websocketSupported = 'WebSocket' in window || 'MozWebSocket' in window;
const cssVariablesSupported = window.CSS && CSS.supports('color', 'var(--fake-var)');

const isBrowserUnsupported = !(websocketSupported && cssVariablesSupported);

const alreadySeen = cookie.get('obsoleBrowserWarningAcknowledged');
const displayWarning = isBrowserUnsupported && !alreadySeen;

const onWarningDismissed = () => cookie.set('obsoleBrowserWarningAcknowledged', 'yes');

ready(function () {
	const obsoleBrowserEl = document.querySelector<HTMLElement>('#browser-obsolete');
	if (obsoleBrowserEl == null) return;
	if (displayWarning) {
		obsoleBrowserEl.style.display = '';
		obsoleBrowserEl.querySelector<HTMLElement>('.dismiss')?.addEventListener('click', onWarningDismissed);
	}
});
