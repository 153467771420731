import {animationFrame} from '@/function';
import {onDelegatedEvent} from "@/domEvents";
import {ready} from "@/ready";
import {data, outerHeight} from "@/br-dom";

const openClass = 'open';
const heightProperty = 'height';

const naturalHeightOf = function(content: HTMLElement): number {

	// Save the current height
	const oldHeight = content.style.height;

	// Turn off transition and let content expand to "natural" (auto) height
	content.style.transition = 'none';
	content.style.height = 'auto';

	// Measure natural height
	const height = outerHeight(content);

	// Set height back, and turn on transition again
	content.style.height = oldHeight;
	content.style.transition = '';

	// Trigger reflow (otherwise transitions won't turn back on)
	content.offsetHeight;

	return height || 0;
};

const open = function(disclosureWidget: HTMLElement) {
	const content = disclosureWidget.querySelector<HTMLElement>('.disclosure-content');
	if (content == null) return;

	content.style[heightProperty] = naturalHeightOf(content) + 'px';
	disclosureWidget.classList.add(openClass);
};

const close = function(disclosureWidget: HTMLElement) {
	const content = disclosureWidget.querySelector<HTMLElement>('.disclosure-content');
	if (content == null) return;

	content.style[heightProperty] = '0';
	disclosureWidget.classList.remove(openClass);
};

const toggleButtonState = function(disclosureWidget: HTMLElement) {
	const toggleButton = disclosureWidget.querySelector<HTMLElement>('.disclosure-toggle');
	if (toggleButton == null) return;
	const inverse = data(toggleButton,'toggleInverse') as {content?: string, title?: string} || {};

	const newContent = inverse['content'];
	const newTitle = inverse['title'];

	if (newContent != null) {
		const oldContent = toggleButton.innerHTML;
		toggleButton.innerHTML = newContent;
		toggleButton.dataset.toggleInverse = `{"content": ${oldContent}}`;
	}

	if (newTitle != null) {
		const oldTitle = toggleButton.title;
		toggleButton.title = newTitle;
		toggleButton.dataset.toggleInverse = `{"title": ${oldTitle}}`;
	}
};

const toggleDisclosure = function(event: UIEvent, element: HTMLElement) {
	const disclosureWidget = element.closest<HTMLElement>('.disclosure');
	if (disclosureWidget == null) return;
	const isOpen = disclosureWidget.classList.contains(openClass);

	if (isOpen) {
		close(disclosureWidget);
	} else {
		open(disclosureWidget);
	}

	toggleButtonState(disclosureWidget);
};

const refresh = () => {
	document.querySelectorAll<HTMLElement>('.disclosure').forEach(function (el) {
		const disclosureWidget = el;
		const isOpen = disclosureWidget.classList.contains(openClass);
		if (isOpen) {
			open(disclosureWidget);
		}
	});
};

ready(function () {
	onDelegatedEvent('click', '.disclosure-toggle', toggleDisclosure);
	window.addEventListener('resize.disclosure', animationFrame(refresh));
});

