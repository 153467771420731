import lotEvents from '@/lot-events';
import { refresh as pageRefresh } from '@/current-page';
import { debounce } from 'ts-debounce';

const refresh = debounce(() => {
	if (document.querySelector('meta[name=refresh-on-regained-connection]')) {
		pageRefresh(new Date().getTime());
	}}, 1000)

const showWarning = debounce(function() {
	if (!lotEvents.isConnected()) {
		document.documentElement.classList.add('lot-events-disconnected');
	}}, 1000);

lotEvents.on('unexpected_disconnect', showWarning);

lotEvents.onConnected(function() {
	document.documentElement.classList.remove('lot-events-disconnected');
	refresh();
});
