import { init } from "@sentry/browser";

console.log('Sentry is loaded:', import.meta.env.VITE_SENTRY_DSN);

if (import.meta.env.VITE_SENTRY_DSN) {
	init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.DEV ? 'development' : 'production',
		release: import.meta.env.VITE_VERSION
	});
}
