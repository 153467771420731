
import clock from '@/server-clock';

const callbacks: ((t: number) => void)[] = [];
let paused = true;
let currentTimer: number | undefined;

const tick = (t: number) => {
	callbacks.forEach(function (c) {
		try {
			c(t);
		} catch (error) {
			console.error(error);
		}
	});
};

const start = function() {
	if (paused) { return; }
	const now = clock.now();
	const nextTick = 1005 - (now % 1000);
	currentTimer = window.setTimeout(function() {
		tick(now);
		start();
	}
	, nextTick);
};

const pause = function() {
	paused = true;
	window.clearTimeout(currentTimer);
};

const register = function(callback: (t: number) => void) {
	paused = false;
	callbacks.push(callback);
	start();
};

const unregister = function(callback: (t: number) => void) {
	const i = callbacks.indexOf(callback);
	if (!(i < 0)) { callbacks.splice(i, 1); }
	if (callbacks.length === 0) { return pause(); }
};

export default {
	everySecond(callback: (t: number) => void ): { cancel(): void } {
		register(callback);
		return {
			cancel() { return unregister(callback); }
		};
	}
};

