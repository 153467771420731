import $ from '@/br-jquery';
import lotEvents from '@/lot-events';
import sleepDetector from '@/sleep-detector';
import { debounce } from 'ts-debounce';
import getScript from "@/getScript";

const myActiveLotsInclude = (id: string) => $(`#toolbar [data-lot-id=${ id }]`).length > 0;

const onError = (reason: unknown) => {
	throw new Error(`Unable to load toolbar, ${reason}`);
}

const loadToolbar = function() {
	console.debug("Loading toolbar...");
	getScript('/m/toolbar', { retries: 3 }).catch(onError);
	// TODO: This code below is an experimental feature. It is commented out because we do not currently
	//       have a way to enable or disable experimental features in script code. We need to find a way
	//       to do this.
	// getScript('/m/recent_lots.js+toolbar', { retries: 3 }).catch(onError);
};

const loadToolbarDebounced = debounce(loadToolbar, 1000);


const refreshIfRelevant = function(lotId: string) {
	if (myActiveLotsInclude(lotId)) {
		loadToolbarDebounced();
	}
};

lotEvents.on('*', refreshIfRelevant);
lotEvents.onConnected(loadToolbarDebounced);
sleepDetector.onAwaken(loadToolbarDebounced);

export default { load: loadToolbarDebounced };
