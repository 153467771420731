import { onDelegatedEvent } from '@/domEvents';
import { debounce } from 'ts-debounce';

declare global {
	interface Window {
		dataLayer: unknown[];
	}
}

window.dataLayer = window.dataLayer || [];

const trackPageView = () => {
	document
		.querySelectorAll<HTMLElement>('[data-track-page-views]')
		.forEach((el) => {
			const data = el.dataset.trackPageViews;
			if (!data) return;
			const payload = JSON.parse(data);
			console.debug('Track page view', payload);

			// If payload is an array, push each item separately
			if (Array.isArray(payload)) {
				payload.forEach((item) => {
					window.dataLayer.push(item);
				});
				return;
			}

			window.dataLayer.push(payload);
		});
};

const trackClick = (e: MouseEvent, el: HTMLElement) => {
	const data = el.dataset.trackClicks;
	if (!data) throw new Error('Missing data-track-clicks payload');
	const payload: unknown = JSON.parse(data);
	console.debug('Track click', payload);
	window.dataLayer.push(payload);
};

type FormControlElement =
	| HTMLInputElement
	| HTMLTextAreaElement
	| HTMLSelectElement;

const trackValidationErrors = (e: Event, form: HTMLFormElement) => {
	const data = form.dataset.trackValidationErrors;
	if (!data) throw new Error('Missing data-track-invalid-fields payload');

	const invalidFields = form.querySelectorAll<FormControlElement>(':invalid');
	const payload: unknown = JSON.parse(data);
	if (typeof payload !== 'object' || payload === null)
		throw new Error('Invalid data-track-invalid-fields payload');

	const invalidFieldsPayload = {
		...payload,
		failReason: Array.from(invalidFields).map((el) => {
			const invalidity: string[] = [];
			for (const key in el.validity as ValidityState) {
				if (el.validity[key as keyof ValidityState]) {
					invalidity.push(key);
				}
			}
			return `${el.name}: ${invalidity.join(', ')}`;
		}),
	};

	console.debug('Track validation errors', invalidFieldsPayload);
	window.dataLayer.push(invalidFieldsPayload);
};

// Track page views
window.addEventListener('turbo:load', trackPageView);

onDelegatedEvent('click', '[data-track-clicks]', trackClick);
onDelegatedEvent(
	'invalid',
	'[data-track-validation-errors]',
	debounce(trackValidationErrors, 100) // Debounced, because invalid event fires for each invalid field, and we only want to track once
);
