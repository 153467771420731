import $ from '@/br-jquery';
import CurrentCustomer from '@/account';
import { TurboBeforeRenderEvent } from '@hotwired/turbo';
import { noop } from '@/function';

const {
    customerNumber
} = CurrentCustomer;

//#
// Finds elements with a `data-customer-number` attribute and change them
// if the space-separated list of values contains the current logged-in user's
// customer number.
//
// Supported changes are:
//
// 1) applying a class
// 2) setting the text content
// 3) setting the inner HTML
//
// To apply a class, specify the class name in a
// `data-class-current-customer` attribute.
//
// To set the text, specify the text in a `content-for-current-customer`
// attribute.
//
// == Example:
//
// If the logged in customer's customer number is 123456, then
//
//     <div data-current-customer="123456 424242"
//         data-content-for-current-customer="You">123456</div>
//
// will be changed to
//
//     <div data-current-customer="123456 424242"
//         data-content-for-current-customer="You">You</div>
//
const humanizeCurrentCustomer =
	(() => {
	if (customerNumber) {
		const selector = `[data-customer-number~="${customerNumber}"]`;
		return function(body = document.body) {
			body.querySelectorAll<HTMLInputElement>(selector).forEach(function(el) {
				const {
					contentForCurrentCustomer,
					classForCurrentCustomer,
					htmlForCurrentCustomer
				} = el.dataset;

				if (contentForCurrentCustomer != null) {
					el.innerText = contentForCurrentCustomer;
				}

				if (classForCurrentCustomer != null) {
					el.classList.add(classForCurrentCustomer);
				}

				if (htmlForCurrentCustomer != null) {
					el.innerHTML = htmlForCurrentCustomer;
				}
			});
		};

	} else {
		return noop;
	}
})();

// Update after an ajax request has completed. This is in case
// the request resulted in a changed DOM.
$(document).ajaxComplete(() => {
	setTimeout(humanizeCurrentCustomer, 10);
});

document.addEventListener('turbo:before-render', (e: Event) => {
	humanizeCurrentCustomer((e as TurboBeforeRenderEvent).detail.newBody);
})
