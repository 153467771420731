import $ from '@/br-jquery';

const cacheHeaders = {
	'Cache-Control': 'no-cache',
	'X-Cache-Bypass': 'yes'
};

let delta = 0;

const now = (): number => Date.now() + delta;

const updateDelta = function(data: { serverTime: number }): void {
	const stop = Date.now();
	const expectedServerTime = Math.round((start + stop) / 2);
	delta = data.serverTime - expectedServerTime;
	console.debug(`Delta: ${ delta }`);
};

const start = Date.now();
$.ajax({
	dataType: 'json',
	url: '/api/0/ping',
	success: updateDelta,
	headers: cacheHeaders
});

export default {
	now
};

