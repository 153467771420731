import Rounder from '@/rounding-ladder';
import onceAsync from '@/once-async';

type ExchangeRates = Record<string, number | undefined>;

interface ExchangeRateResponse {
	baseCurrency: string;
	exchangeRates: ExchangeRates;
}

const exchangeDecade: number[] = [
	100, 102, 104, 105, 106, 108, 110, 112, 114, 115, 116, 118, 120, 122, 124,
	125, 126, 128, 130, 132, 135, 138, 140, 142, 145, 148, 150, 152, 155, 158,
	160, 162, 165, 168, 170, 172, 175, 178, 180, 182, 185, 188, 190, 192, 195,
	198, 200, 202, 205, 208, 210, 212, 215, 218, 220, 222, 225, 228, 230, 232,
	235, 238, 240, 242, 245, 248, 250, 255, 260, 265, 270, 275, 280, 285, 290,
	295, 300, 305, 310, 315, 320, 325, 330, 335, 340, 345, 350, 355, 360, 365,
	370, 375, 380, 385, 390, 395, 400, 405, 410, 415, 420, 425, 430, 435, 440,
	445, 450, 455, 460, 465, 470, 475, 480, 485, 490, 495, 500, 510, 520, 530,
	540, 550, 560, 570, 580, 590, 600, 620, 650, 680, 700, 720, 750, 780, 800,
	820, 850, 880, 900, 920, 950, 980,
];
const exchangeRounder = new Rounder(exchangeDecade);

const exchangeRates: Record<string, ExchangeRates> = {};

const fetchExchangeRates = onceAsync(async function fetchExchangeRates(
	base: string
): Promise<ExchangeRates> {
	try {
		const response = await fetch(`/api/0/exchange-rates?base=${base}`);
		const data = (await response.json()) as ExchangeRateResponse;
		return data.exchangeRates;
	} catch (e) {
		throw new Error(`Failed to fetch exchange rates for ${base}`, { cause: e });
	}
});

const getExchangeRate = async (from: string, to: string): Promise<number> => {
	if (exchangeRates[from] == null) {
		try {
			exchangeRates[from] = await fetchExchangeRates(from);
		} catch (e) {
			console.error(e);
			exchangeRates[from] = {};
		}
	}

	const rates = exchangeRates[from];
	const rate = rates[to];
	if (rate == null) throw new Error(`No exchange rate from ${from} to ${to}`);

	return rate;
};

/**
 * Convert an amount from one currency to a rounded amount in another.
 *
 * @param amount The amount to convert
 * @param from The currency to convert from
 * @param to The currency to convert to
 */
export const exchange = async (
	amount: number,
	from: string,
	to: string
): Promise<number> => {
	const rate = await getExchangeRate(from, to);
	return exchangeRounder.round(amount * rate);
};
