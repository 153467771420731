// Various form related stuff:
//
// - Keeps track of which fields are filled and not.
//   Fields which have been filled get a `non-empty` class applied.
//
// - Fields with the attribute `data-submit-form-on-change` trigger their form
//   to be submitted, when they change
//
// - Forms get a `dirty` class applied, when they are changed.

import { onDelegatedEvent } from '@/domEvents';
import { ready } from '@/ready';
import { visit } from '@hotwired/turbo';

const isEmpty = function (el: HTMLElement): boolean {
	if (!(el instanceof HTMLInputElement) && !(el instanceof HTMLTextAreaElement))
		return false;

	if (
		el instanceof HTMLInputElement &&
		(el.type === 'checkbox' || el.type === 'radio' || el.type === 'date')
	) {
		// Check-boxes and radio buttons don't use "floating labels"
		return false;
	}
	return el.value.length === 0;
};

// Set `non-empty` class on input fields that are not empty
function markFormAsDirty(_ev: InputEvent, form: HTMLElement) {
	form.classList.add('dirty');
}

const toggleNonEmpty = (el: HTMLElement) => {
	el.classList.toggle('non-empty', !isEmpty(el));
	if (isEmpty(el)) {
		el.removeAttribute('value');
	}
};

const submitForm = (event: InputEvent, el: HTMLElement) => {
	const form = el.closest('form');
	if (form == null) return;

	// We use requestSubmit() instead of form.submit(), because the latter
	// doesn't work with Turbo.
	//
	// requestSubmit() is not supported in Safari < 16, but luckily Turbo
	// polyfills it for us.
	form.requestSubmit();

	form.classList.add('submitted');
};

onDelegatedEvent(
	['focus', 'blur', 'change'],
	'textarea, input:not([type=submit])',
	(_ev: InputEvent, el: HTMLElement) => {
		toggleNonEmpty(el);
	}
);
onDelegatedEvent('change', '[data-submit-form-on-change]', submitForm);
onDelegatedEvent('change', 'form', markFormAsDirty);
ready(() => {
	document
		.querySelectorAll<HTMLInputElement>('input:not([type=submit])')
		.forEach((el) => {
			toggleNonEmpty(el);
		});
});

onDelegatedEvent('change', '[data-refresh-on-change]', (event, el) => {
	if (event.target == null) return;
	const name = (event.target as HTMLInputElement | HTMLTextAreaElement).name;

	const form = el.closest('form');
	if (form == null) return;

	const data = new FormData(form);

	// Get the *last* value for the given name.
	// This is needed, because checkboxes don't submit their value, unless
	// they are checked. To get around this, we use a hidden field with the
	// same name, which is submitted if the checkbox is not checked.
	// However, if the value is checked, the *both* values are submitted,
	// but we're only interested in the last one.
	// On the server side, this is all taken care of by Rails, but on the
	// client side we need to do this manually.
	const values = data.getAll(name);
	if (values.length === 0) return;
	const val = values[values.length - 1];

	const url = new URL(window.location.href);
	url.searchParams.set(name, val.toString());

	visit(url, { action: 'replace' });
});
