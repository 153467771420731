<template>
	<span v-if="!expanded" :title="exchanged">{{ original }}</span>
	<span v-else>
		{{ original }}
		<span v-if="showExchanged">({{ exchanged }})</span>
	</span>
</template>

<script setup lang="ts">
import { exchange } from '@/currencies';
import { selectedCurrency } from '@/currency-selector';
import { localizedCurrencyRange } from '@/i18n';
import { computed, onMounted, ref } from 'vue';

/**
 * A component that displays a currency range in the currently selected currency.
 */
const props = defineProps({
	/**
	 * The lower bound of the range expressed in the default currency.
	 */
	from: {
		type: Number,
		required: true,
	},
	/**
	 * The upper bound of the range expressed in the default currency.
	 */
	to: {
		type: Number,
		required: true,
	},
	/**
	 * The default currency.
	 */
	currency: {
		type: String,
		required: true,
	},

	/**
	 * If true, the range will be shown both in the original and selected currency.
	 */
	expanded: {
		type: String,
		default: null,
	},
});

const exchanged = ref('');

const updateExchangedAmount = () => {
	Promise.all([
		exchange(props.from, props.currency, selectedCurrency.value),
		exchange(props.to, props.currency, selectedCurrency.value),
	]).then(([exchangedFrom, exchangedTo]) => {
		exchanged.value = localizedCurrencyRange(
			exchangedFrom,
			exchangedTo,
			selectedCurrency.value
		);
	});
};

onMounted(() => {
	updateExchangedAmount();
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const original = computed(() => {
	return localizedCurrencyRange(props.from, props.to, props.currency);
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const showExchanged = computed(() => {
	return props.currency !== selectedCurrency.value;
});
</script>
