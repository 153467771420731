import $ from '@/br-jquery';

const openClass = 'open';
const ESCAPE_KEY = 27;

const closeIfFocusLost = function(el: HTMLElement): void {
	const $button = $(el);

	// Don't close if an element inside gained focus
	const activeElement = document.activeElement;
	if (activeElement == null) return;
	if ($button.find(activeElement).length > 0) {
		return;
	}

	$button.removeClass(openClass);
};

$(document)
	.on('focus.dropdown', '.select, [data-modal]', function() {
		const $button = $(this);
		$button.addClass(openClass);
}).on('keyup.dropdown', '.select.open, .select.open *, [data-modal], [data-modal] *', function(e) {
		if (e.keyCode === ESCAPE_KEY) {
			$(this).blur();
			e.preventDefault();
		}
	}).on('focusout.dropdown', '.select, [data-modal]', closeDelayed)

	.on('turbo:render', () => $('.open[data-modal]').removeClass(openClass));

function closeDelayed(this: HTMLElement) {
	setTimeout((button: HTMLElement) => {
		closeIfFocusLost(button);
	}, 1, this);
}

