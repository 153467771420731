/**
 * This module aims to be a library of our customer Unobtrusive JavaScript (UJS)
 * behaviours, as well as a place to attach those behaviours to the DOM.
 * Basically, this is where we identify elements that we want to attach some
 * JavaScript behavior to, and then attach that behavior.
 */
import { visit } from '@hotwired/turbo';

/**
 * Look for a data-reload-delay attribute on the document, and if it exists,
 * reload the page after the specified delay. This is useful for pages that
 * need to be refreshed after a certain amount of time, and don't have a way
 * to get notified, when the changes they are waiting for, have happened.
 */
const handleDelayedReload = () => {
	const reloadDelayString = document
		.querySelector('[data-reload-delay]')
		?.getAttribute('data-reload-delay');
	const reloadDelay = Number(reloadDelayString);
	if (reloadDelay > 0) {
		setTimeout(() => {
			visit(window.location.href);
		}, reloadDelay);
	}
};

const onPageLoad = () => {
	handleDelayedReload();
};

export const init = () => {
	document.addEventListener('turbo:load', onPageLoad);
};
