/**
 * Wraps an async function so that it can only be called once.
 * Even if the first call is still pending, subsequent calls will return the
 * same promise.
 * A limitation of this implementation is that it does not handle being called
 * with different arguments. If resulting function is called with different
 * arguments, it will still return the result of the first call.
 */
export default function onceAsync<
	T extends (...args: unknown[]) => Promise<unknown>
>(wrapped: T): T {
	let result: ReturnType<T> | undefined;
	let pending: Promise<ReturnType<T>> | undefined;
	let isPending = false;
	return async function (...args: Parameters<T>): Promise<ReturnType<T>> {
		if (result !== undefined) return result;

		if (isPending) {
			return pending;
		}

		isPending = true;
		pending = wrapped(...args);
		try {
			result = await pending;
		} catch (e) {
			throw e;
		} finally {
			isPending = false;
		}
		return result;
	};
}
