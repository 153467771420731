import $ from '@/br-jquery'

function updateStates(this: HTMLSelectElement) {
	const countryId = this.value;
	if (countryId == null) return;

	$.getScript(`/m/countries/${ countryId }`);
}

$(document).on('change', 'select#country', updateStates);
