<template>
  <a
    :href="href"
    :class="{ 'tw-bg-br-gray1': selected }"
    class="big-text lh-2-1 block"
  >»{{ term }}«</a>
</template>

<script lang="ts">
import {searchPath} from '@/routes';
import {defineComponent} from "vue";

export default defineComponent({
	name: "SearchSuggestion",
	props: {
	'term': { type: String, default: ''},
	'selected': { type: Boolean, default: false }
	},
	computed: {
		href(): string { return searchPath(this.term) }
	},
	methods: {
		foo() { console.log('foo') },
		bar() { this.foo(); }
	}
})
</script>
