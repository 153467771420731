import {visit} from '@hotwired/turbo';
import {ready} from "@/ready";
import {onDelegatedEvent} from "@/domEvents";

// To achieve higher cache-ability of lot list items, we strip the query parameters
// from each list item link, and place it instead in a data attribute on the containing
// element. We the intercept clicks on these links and re-append the query parameters
// before visiting the destination.
//
const addQueryToLinkTarget = function(e: MouseEvent, el: HTMLElement): void {
	const listElement = el.closest<HTMLElement>('.lots[data-query]');
	if (listElement == null) return;

	const query = listElement.dataset.query;
	// In the unlikely event that the data attribute is present but empty,
	// we just make the link behave as normal.
	if (query == null) return;

	const destinationUrl = new URL((el as HTMLAnchorElement).href);
	const extraParams = new URLSearchParams(query);

	for (const [key, value] of extraParams) {
		destinationUrl.searchParams.append(key, value);
	}

	e.preventDefault();
	e.stopPropagation()

	visit(destinationUrl.toString());
};

ready(function () {
	onDelegatedEvent('click', '.lots[data-query] a[href]', addQueryToLinkTarget);
});

