import Clock from '@/server-clock';
import {onDelegatedEvent} from "@/domEvents";

onDelegatedEvent('submit', '[data-upon-submit=add-timestamp]', function(event, el) {
	const inputEl = document.createElement('input');
	inputEl.type = 'hidden';
	inputEl.name = 'client_timestamp';
	inputEl.value = Clock.now().toString();
	el.appendChild(inputEl);
});
