// Once started, this will listen for interactions on the page. Upon the first
// occurrence of an interaction, it will track the interaction and then stop.

export const startTracking = () => {
	const interactionEvents = ['click', 'submit', 'change', 'turbo:visit'];

	const abortController = new AbortController();
	const signal = abortController.signal;

	const trackInteraction = (event) => {
		// Ignore if the target has a data-ignore-interaction attribute
		if (
			event.target instanceof Element &&
			event.target.matches('[data-ignore-interaction]')
		) {
			return;
		}

		abortController.abort();
		fetch('/m/goals/interaction/track', { method: 'POST' });
	};

	interactionEvents.forEach((eventName) => {
		document.addEventListener(eventName, trackInteraction, { signal });
	});
};
