// Lazy load images marked up with the `data-lazy-image` attribute as they enter into the visible viewport
//
// The `data-lazy-image` attribute must specify a set of attributes (in JSON format)
// to be applied as the element enters the viewport:
//
// e.g: <img src="placeholder-image.jpg" data-lazy-image="{src=\"actual-image.jpg\" class="in-viewport"}"/>
//
// Note that:
// - Any attribute, not just `src` can in principle be updated when the element appears in the viewport.
// - Existing attributes are overwritten by those specified. This is especially important to consider, if
//   updating style or class attributes.

import $ from '@/br-jquery';
import {animationFrame} from '@/function';
import {ready} from '@/ready';

const isElementInsideViewport = function(el: HTMLElement, threshold = 0): boolean {
	const {
		height: viewportHeight,
		width: viewportWidth
	} = document.documentElement.getBoundingClientRect();

	const {
		left: elLeft,
		top: elTop,
		bottom: elBottom,
		right: elRight
	} = el.getBoundingClientRect();

	// Completely above the visible viewport
	if (elBottom < -threshold) { return false; }

	// Completely below the visible viewport
	if (elTop > viewportHeight + threshold) { return false; }

	// Completely to the left of the visible viewport
	if (elRight < -threshold) { return false; }

	// Completely to the right of the visible viewport
	if (elLeft > viewportWidth + threshold) { return false; }

	// The element is at least partially with the visible viewport
	return true;
};

const load = function(img: HTMLImageElement): void {
	const $img = $(img);
	if (img.dataset.alreadyLoaded) { return; }

	console.debug('Loading image', $img.data('lazyImage'));
	const originalSrc = img.src;
	$img.attr($img.data('lazyImage'))

	img.dataset.alreadyLoaded = "✔️"
	if (img.src !== originalSrc) {
		return img.classList.add('loading');
	}
};

const loadImagesInView = () => {
	Array.from(document.querySelectorAll<HTMLImageElement>('[data-lazy-image]'))
		.filter((el) => isElementInsideViewport(el, 360))
		.forEach(load);
};

const loadImagesInViewInNextAnimationFrame = animationFrame(loadImagesInView);

const init = function() {
	loadImagesInView();
	$(document).on('turbo:render', loadImagesInView);

	$(window).on('scroll resize', loadImagesInViewInNextAnimationFrame);

	// Some elements may appear in the viewport as the result of an animation or transition
	$(document).on('transitionend animationend', loadImagesInViewInNextAnimationFrame);
};

ready(init);



