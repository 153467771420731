import TimeDistance from '@/timeDistance';
import serverClock from '@/server-clock';
import ticker from '@/ticker';
import {inWords} from '@/relative-time'
import {capitalizeFirstLetterOf, translate as t} from '@/i18n';
import {ready} from '@/ready';
import {data} from '@/br-dom';

const defaultOptions = {
	from: TimeDistance.hours(-1).inSeconds(),
	to: 0,
	showSeconds: true,
	nowString: t('countdown.now'),
	roundToQuarterHour: false,
	capitalizeFirstLetter: false
};

function showRelativeTime(timeElement: HTMLTimeElement, now: number): void {
	const absoluteTime = Date.parse(timeElement.dateTime);
	const options = Object.assign({...defaultOptions}, data(timeElement, 'relativeTime'));

	const timeDistanceFromNow = TimeDistance.millis(now - absoluteTime);

	if (timeDistanceFromNow.inSeconds() > options.to)
		return;

	if (timeDistanceFromNow.inSeconds() <= options.from) {
		return
	}

	if (!timeElement.title) {
		timeElement.title = timeElement.innerText.trim()
	}

	let timeLeftInWords = inWords(TimeDistance.millis(-timeDistanceFromNow.inMilliseconds()), options);

	if (options.capitalizeFirstLetter) {
		timeLeftInWords = capitalizeFirstLetterOf(timeLeftInWords);
	}
	if (timeElement.innerText !== timeLeftInWords) {
		timeElement.innerText = timeLeftInWords;
	}
}

function updateAll(t: number): void {
	const selector = 'time.relative-time,time[data-relative-time]';

	const timers = document.querySelectorAll(selector);

	if (!(timers.length > 0)) { return; }

	timers.forEach((el) => {
		showRelativeTime(el as HTMLTimeElement, t);
	});
}

const init = function(): void {
	updateAll(serverClock.now());
	ticker.everySecond(updateAll);
};

ready(init);
