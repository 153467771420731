import {onDelegatedEvent} from "@/domEvents";

// This adds all event handlers expected of button to an element such as a
// <div> that does not have them. This is required if e.g. the element has
// role=button.
//
// Importantly, the event handlers are not added to the element itself, but
// to <body> such that they persist across Turbo page loads.
//
// Based on example code from https://www.w3.org/TR/wai-aria-practices/examples/button/button.html

export default function buttonBehavior(selector: string, action: (el: HTMLElement) => void): void {

	onDelegatedEvent('click', selector, function (event, el) {
		event.preventDefault();
		action(el);
	});

	onDelegatedEvent('keydown', selector, function (event, el) {
		// The action button is activated by space on the keyup event, but the
		// default action for space is already triggered on keydown. It needs to be
		// prevented to stop scrolling the page before activating the button.
		if (event.keyCode === 32) {
			event.preventDefault();
			return false;
		}

		// If enter is pressed, activate the button
		if (event.keyCode === 13) {
			event.preventDefault();
			action(el);
			return false;
		}
	});

	onDelegatedEvent('keyup', selector, function (event, el) {
		if (event.keyCode === 32) {
			event.preventDefault();
			action(el);
			return false;
		}
	});
}

