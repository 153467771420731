import $ from '@/br-jquery';

const followNoHistoryLink = function(this: HTMLAnchorElement, e: Event) {
	e.preventDefault();
	return window.location.replace(this.href);
};

$(document).ready(() =>
	// Attach event listener to body to avoid having to
	// attach on every page load
	$(document).on('click', 'a[data-no-history]', followNoHistoryLink));

