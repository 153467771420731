// Force recompute for older Safari browsers <16, maybe < 17. Fixes image issues
// with async loading (Turbo), srcset and width|height: auto ending up with a 0
// width|height
const srcSetImageFixer = function() {
	setTimeout(() => {
		const images = document.querySelectorAll('img[srcset]');
		images.forEach(img => {
			if (img.clientHeight == 0 || img.clientWidth == 0) {
				img.outerHTML = img.outerHTML;
			}
		});
	}, 1000);
}

const isSafariUnder17 = function() {
	const userAgent = navigator.userAgent.toLowerCase();
	const safariMatch = userAgent.match(/version\/(\d+(\.\d+)?).*safari/);

	if (safariMatch) {
		const version = parseFloat(safariMatch[1]);
		return version < 17;
	}

	return false;
}

if (isSafariUnder17()) {
	document.addEventListener('turbo:load', srcSetImageFixer);
}
