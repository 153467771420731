<template>
  <section
    v-cloak
    id="global-search"
    :aria-label="t('global.search_suggestions')"
    class="tw-text-near-black tw-bg-white content box-shadow position-below-bottom"
    @focusin="onFocusIn"
    @focusout="onFocusOut"
  >
    <div class="row tw-p-5">
      <section class="global-search-suggestions">
        <section
          v-if="showRecent"
          class="content"
        >
          <header class="flex-row spread-out">
            <h1 class="normal-text tw-my-2.5">
              {{ t('global_header.search.your_recent_searches') }}:
            </h1>
            <button
              class="no-grow secondary-action"
              @click="clearRecentSearches"
            >
              {{ t('global_header.search.clear') }}
            </button>
          </header>
          <search-suggestion
            v-for="suggestion in recentSearches"
            :key="suggestion"
            :term="suggestion"
          />
        </section>
        <section
          v-if="showPopular"
          class="tw-mt-5 content"
        >
          <h1 class="normal-text tw-my-2.5">
            {{ t('global_header.search.popular') }}:
          </h1>
          <search-suggestion
            v-for="suggestion in popularSearches"
            :key="suggestion"
            :term="suggestion"
          />
        </section>
        <section v-if="showSuggestions">
          <div class="suggestions context content">
            <h1 class="normal-text tw-my-2.5">
              {{ t('global_header.search.suggestions') }}:
            </h1>
            <search-suggestion
              v-for="suggestion in controller.suggestions"
              :key="suggestion"
              :term="suggestion"
              :selected="suggestion === selectedSuggestion"
            />
          </div>
        </section>
        <section
          v-if="showNoSuggestions"
          class="tw-mt-5"
        >
          <div class="suggestions weak">
            {{ t('global_header.search.no_suggestions') }}
          </div>
        </section>
      </section>
    </div>
  </section>
</template>

<script lang="ts">
import { ajax, getJSON } from '@/br-jquery';
import {translate} from '@/i18n';
import searchSuggestionsController from '@/search-suggestions-controller';
import {debounce} from "ts-debounce";
import SearchSuggestion from '@/components/SearchSuggestion.vue'
import {suggestionsPath} from '@/routes';
import {defineComponent} from "vue";

const lang = document.documentElement.lang;

function getSuggestions(query: string, callback: (query: string, results: string[]) => void) {
	const path = suggestionsPath(query, lang);
	getJSON(path).
		then((results) => {
			callback(query, results);
		}).
		catch(() => callback(query, []));
}

function loadRecentSearches(callback) {
	getJSON('/m/recent_searches').then(callback);
}

const getSuggestionsDebounced = debounce(getSuggestions, 100);

const I18nVue = {
	methods: {
		t: translate
	}
}
export default defineComponent({

	components: { 'search-suggestion': SearchSuggestion },

	mixins: [ I18nVue ],

	props: {
		query: {
			type: String, default: ''
		}
	},

	emits: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		'suggestion-selected': (index: number, suggestion: string) => true,
		'focusin': () => true,
		'focusout': () => true
	},

	data: function () {
		return {
			currentQuery: this.query,
			controller: searchSuggestionsController.state.value,
			recentSearches: [],
			popularSearches: [
				"Koglen",
				"Arne Jacobsen",
				"Chateau Latour",
				"Fabergé"
			],
			loading: false
		}
	},

	computed: {

		queryPresent(): boolean { return this.query !== ''; },

		suggestionsAny(): boolean { return this.controller.suggestions.length > 0; },

		showRecent(): boolean {
			if (this.recentSearches.length === 0) return false;

			if (this.query === '') return true;

			return this.query === this.originalQuery;
		},

		showPopular(): boolean {
			return this.query === '';
		},

		showSuggestions(): boolean {
			if (!this.queryPresent) return false;
			if (!this.suggestionsAny) return false;

			return true;
		},

		showNoSuggestions(): boolean {
			if (!this.queryPresent) return false;
			if (this.suggestionsAny) return false;
			if (this.loading) return false;

			return true;
		},

		selectedIndex(): number {
			return this.controller.selectedIndex;
		},

		selectedSuggestion(): string {
			if (this.selectedIndex < 0) return '';

			return this.controller.suggestions[this.selectedIndex]
		}
	},

	watch: {
		query(): void {
			this.loadSuggestions();
		},

		selectedIndex(): void {
			this.$emit('suggestion-selected', { index: this.selectedIndex, suggestion: this.selectedSuggestion })
		}
	},

	mounted(): void {
		this.loadSuggestions();
		this.loadRecentSearches();
	},

	methods: {
		onFocusIn(): void {
			this.$emit('focusin')
		},

		onFocusOut(): void {
			this.$emit('focusout')
		},

		loadSuggestions(): void {
			this.loading = true;
			getSuggestionsDebounced(this.query, (q, suggestions) => {
				if (q !== this.query) return;

				this.loading = false;
				searchSuggestionsController.updateSuggestions(suggestions);
			})
		},

		loadRecentSearches(): void {
			loadRecentSearches((results) => {
				this.recentSearches = results;
			});
		},

		clearRecentSearches(): void {
			this.recentSearches = []
			ajax({
				url: '/m/recent_searches',
				type: 'DELETE'
			}).then(
				this.loadRecentSearches
			);
		}
	}
});
</script>
