import {getScript as $getScript} from '@/br-jquery';

interface FetchScriptOptions {
	retries?: number
}

export default async (url: string, options: FetchScriptOptions = {}): Promise<void> => {

	let { retries } = Object.assign({
		retries: 0
	}, options);

	const getScript = async () => {
		try {
			await $getScript(url);
		} catch (e) {
			console.warn(`Failed to get script "${url}". Retries left: ${ retries }`)
			if (retries > 0) {
				retries--;
				await getScript()
			}
			throw e;
		}
	};

	await getScript();
}
