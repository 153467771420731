import $ from '@/br-jquery';
import { StreamElement, TurboBeforeStreamRenderEvent } from '@hotwired/turbo';
import { onDelegatedEvent } from '@/domEvents';
import { ready } from '@/ready';

const initEveryTime = () => {
	document.querySelectorAll('[data-auto-dismiss]').forEach(function(el) {
		const delay = Number((el as HTMLElement).dataset['autoDismiss']);
		window.setTimeout(() => {
			return $(el).slideUp();
		}, delay);
	});
};

const initFirstTime = function() {
	initEveryTime();
	// Warnings and errors should hang around until actively dismissed
	onDelegatedEvent('click', '.flash .dismiss', (event, el) => {
		$(el).closest('.flash').slideUp();
	});
};


ready(initFirstTime);
document.addEventListener('turbo:render', initEveryTime);

document.addEventListener('turbo:before-stream-render', (_ev) => {
	const ev = _ev as TurboBeforeStreamRenderEvent;
	if (ev.target == null) return;
	if (!(ev.target instanceof StreamElement)) return;

	if (ev.target.templateContent.querySelector('#flash') == null) return;
	setTimeout(initEveryTime, 100);
});
