import $ from '@/br-jquery';
import CurrentUser from '@/account';
import buttonBehavior from '@/button-behaviour';
import Toolbar from '@/toolbar';

function refreshBookmarks (callback: () => void) {

	// Update toolbar
	Toolbar.load();

	// Refresh user's bookmarks stylesheet
	//
	// Instead of simply updating the existing link tag in head, we clone it
	// and insert if after. When the updated stylesheet has loaded, we remove
	// the old on. This prevents the bookmark icons from "flashing" off in the
	// time it takes to update the stylesheet.
	const oldBookmarkStyles = document.querySelector('#bookmark-styles') as HTMLLinkElement;
	const newBookmarkStyles = oldBookmarkStyles.cloneNode(true) as HTMLLinkElement;

	oldBookmarkStyles.id = 'old-bookmark-styles';

	const href = new URL(newBookmarkStyles.href);
	href.search = `v=${ Date.now() }`;

	newBookmarkStyles.onload = function removeOldStylesheet () {
		$('#old-bookmark-styles').remove();
		callback();
	};

	newBookmarkStyles.href = href.toString();

	const parent = oldBookmarkStyles.parentNode as Node;

	// Insert new stylesheet after the old one
	parent.insertBefore(newBookmarkStyles, oldBookmarkStyles.nextSibling);
}

function bookmarkPath (lotId: string) {
	return `/m/lots/${ lotId }/bookmark`;
}

function addBookmark (lotId: string, callback: () => void): void {
	if (CurrentUser.isLoggedIn) {
		$.ajax({
			method: 'post',
			url: bookmarkPath(lotId)
		}).done(function () {
			refreshBookmarks(callback)
		});
	} else {
		// This leads to the login page, and will add the bookmark after login
		document.location.href = `/m/lots/${ lotId }/add_bookmark`
	}
}

function removeBookmark (lotId: string, callback: () => void): void {
	$.ajax({
		method: 'delete',
		url: bookmarkPath(lotId)
	}).done(function () {
		refreshBookmarks(callback)
	});
}

$(document).ready(function () {

	buttonBehavior('[data-bookmark]', function (button) {
		const lotId = button.dataset.bookmark as string;
		const $button = $(button);
		const $oppositeStateButton = $(`[data-no-bookmark=${ lotId }]`);

		// Use temporary element styling to immediately update the apparent
		// state of the bookmark button
		$button.css({ display: 'none' });
		$oppositeStateButton.css({ display: 'block' });

		// Focus on opposite state button, so the user can toggle back
		// just by hitting the space button again.
		$oppositeStateButton.focus();

		removeBookmark(lotId, function () {
			// Remove temporary element styling when the new stylesheet has
			// loaded
			$button.css({ display: '' });
			$oppositeStateButton.css({ display: '' });
		});
	});

	buttonBehavior('[data-no-bookmark]', function (button) {
		const lotId = button.dataset.noBookmark as string;
		const $button = $(button);
		const $oppositeStateButton = $(`[data-bookmark=${ lotId }]`);

		// Use temporary element styling to immediately update the apparent
		// state of the bookmark button
		$button.css({ display: 'none' });
		$oppositeStateButton.css({ display: 'block' });

		// Focus on opposite state button, so the user can toggle back
		// just by hitting the space button again.
		$oppositeStateButton.focus();

		addBookmark(lotId, function () {
			// Remove temporary element styling when the new stylesheet has
			// loaded
			$button.css({ display: '' });
			$oppositeStateButton.css({ display: '' });
		});
	});
});
