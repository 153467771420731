/**
 * This script looks for elements with the `data-popup-delay` attribute and
 * starts a timer to show the element after the specified delay. If the user
 * interacts with the page by scrolling or clicking, the timer is reset.
 */
import { startTracking } from '@/track-interaction';

const init = () => {
	// Find delayed pop-up elements and initialize them
	document.querySelectorAll('[data-popup-delay]').forEach((element) => {
		const delay = Number(element.getAttribute('data-popup-delay')) * 1000;
		const triggerEvents: [string] =
			element.dataset.popupTriggerEvents?.split(' ') ?? [];
		const abortController = new AbortController();

		let timer: ReturnType<typeof setTimeout> | undefined;

		console.log('init delayed pop-up', delay);

		const setTimer = () => {
			clearTimeout(timer);
			timer = setTimeout(showPopup, delay);
		};

		const resetTimer = () => {
			if (timer === undefined) return;
			setTimer();
		};

		const showPopup = () => {
			// Remove aria-hidden attribute to make the element visible
			element.removeAttribute('aria-hidden');
			cleanup();
			startTracking();
		};

		const cleanup = () => {
			clearTimeout(timer);
			abortController.abort();
		};

		if (triggerEvents.length == 0) {
			setTimer();
		} else {
			triggerEvents.forEach((eventName) => {
				document.addEventListener(eventName, setTimer, {
					signal: abortController.signal,
				});
			});
		}

		// We don't start the timer until the user has scrolled a bit
		// If the timer has already been started, we reset it
		window.addEventListener('scroll', resetTimer, {
			signal: abortController.signal,
		});

		// We also reset the timer if the user clicks anywhere on the page
		window.addEventListener('click', resetTimer, {
			signal: abortController.signal,
		});

		// If the user navigates away from the page, we stop the timer and remove event listeners
		window.addEventListener('turbo:before-visit', cleanup, {
			signal: abortController.signal,
		});
	});

	// Add delegated event listener for closing pop-ups
	document.addEventListener('click', (event) => {
		if (event.target.matches('[data-popup-close]')) {
			const popup = event.target.closest('[data-popup-delay]');
			if (popup) {
				popup.setAttribute('aria-hidden', 'true');
				const popupId = popup.dataset.popupId;
				// If the popup has an ID, store a cookie to prevent it from showing again
				if (popupId) {
					const cookieName = `popup-${popupId}-dismissed`;
					document.cookie = `${cookieName}=true; path=/; max-age=31536000`;
				}
			}
		}
	});
};

document.addEventListener('turbo:load', init);
