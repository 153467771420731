import {ready} from '@/ready';
import {cache, TurboBeforeRenderEvent} from "@hotwired/turbo";
import {createApp} from "vue";
import {GlobalHeader, globalHeaderSelector} from "@/components/GlobalHeader";

let initOnRender = true;

function init () {

	// Early return if there is no menu. This will be the case on e.g. the
	// confirm bid page.
	if (!document.querySelector(globalHeaderSelector)) return;

	initOnRender = false;

	const app = createApp(GlobalHeader);

	app.directive('focus', {
		// When the bound element is inserted into the DOM...
		mounted: function (el) {
			// Focus the element
			el.focus()
	}});

	app.mount(globalHeaderSelector);
	let mounted = true;

	document.addEventListener('turbo:before-render', (e: Event): void => {
		const event = e as TurboBeforeRenderEvent;
		if (!event.detail.newBody.querySelector(globalHeaderSelector)) {
			if (!mounted) return;

			app.unmount();
			mounted = false;
		}
	});

}

// Init menu upon first page load
ready(init);

// On every Turbo *render* (unlike 'load', 'render' also fires, when a
// cached page is restored)
document.addEventListener('turbo:render', function () {

	// Since the menu is "turbo-permanent", we don't need to
	// initialize it on every page render...
	if (initOnRender) {
		init();
	}

	if (!document.getElementById('global-header')) {
		// ... however, we *do* need to re-initialize it after we have
		// visited a page that does not contain it.
		initOnRender = true;

		// We also have to make sure, we re-initialize it from pristine
		// markup, not the markup that was the result of whatever state the
		// menu was in, when the page was cached.

		// Because the menu is "turbo-permanent", when Turbo
		// updates the cached page with fresh content from the backend, it
		// keeps the menu from the cached page. This is not a problem in
		// most cases, because if the menu was already present on the page
		// being navigated *from*, that menu will have already replaced the
		// one contained on the cached page, when the cached page gets
		// restored. In other words, as long as we navigate between pages
		// that both contain the menu, we will always reuse the menu that
		// is already in the active DOM, with all its event handlers etc.
		// still attached.

		// However, if the page being navigated from did not have the menu,
		// the menu on the cached page will be used, and it will not work,
		// because it will be missing all of its event handlers etc. as
		// well as having its markup in whatever state it was at the moment
		// the page was cached, meaning not in a state required for it to
		// be re-initialized. For this reason, we have to purge the cache
		// whenever we navigate to a page with no menu. This ensures we
		// later get to a page with a menu, we don't attempt to initialize
		// it using the mangled markup that was the menu state, when the
		// page was cached.

		console.info('Clearing Turbo cache...');

		// Defer clearing of cache, because even though Turbo has
		// already created a snapshot of the previous page at this point,
		// it has deferred storing it in the cache (presumably to be able
		// to display the next page faster).
		setTimeout(() => cache.clear(), 1);
	}
})
