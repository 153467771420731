// Inspired bu this: https://my.onetrust.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046
declare global {
	interface Window { OneTrust: typeof OneTrust; } // TS2339
}

export const reloadOTBanner = (): void => {
	const otConsentSdk = document.getElementById('onetrust-consent-sdk');
	if (otConsentSdk) {
		otConsentSdk.remove();
	}

	if (window.OneTrust != null) {
		OneTrust.Init();

		setTimeout(function () {
			OneTrust.LoadBanner();
		}, 1000);
	}
};
