import {animationFrame} from '@/function';
import {offset} from "@/br-dom";
import {ready} from "@/ready";


// Ensures that elements with the `data-align-baseline` attribute
// have the same height if they are offset the same amount from the
// top of the document
const alignBaseLine = function(): void {

	// Group elements with the attribute `data-align-baseline` according to their offset from the top of the document
	const groups = Array.from(document.querySelectorAll<HTMLElement>('[data-align-baseline]')).reduce(function(groups: {[offsetTop: number]: HTMLElement[]}, item) {
		const coords = offset(item);
		if (coords == null) return groups;

		const group = Math.round(coords.top);
		if (groups[group] == null) {
			groups[group] = [];
		}

		groups[group].push(item);
		return groups;
	}
	, {});

	// For each group, find the tallest element and set the height of all elements in the group to that height
	for (const group in groups) {
		const items = groups[group];
		const max = items.reduce(function(maxHeight: number, item: HTMLElement) {
			item.style.minHeight = '';
			const itemHeight = item.getBoundingClientRect().height;
			if (maxHeight < itemHeight) {
				return itemHeight;
			} else {
				return maxHeight;
			}
		}
		, 0);

		items.forEach(item => {
			item.style.minHeight = max + 'px';
		})
	}
};

ready(function() {
	alignBaseLine();
	window.addEventListener('resize', animationFrame(alignBaseLine));
	document.addEventListener('turbo:render', alignBaseLine)
});

